"use client"

import { createContext, useContext } from "use-context-selector";
import {useEffect} from 'react'
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { Spinner, Button} from "@nextui-org/react";

const AuthContext = createContext({
  session: null,
  status: null
});

export const AuthProvider = ({ children }) => {
  const { data: session, status } = useSession();
  const router = useRouter();
  console.log('获取session', session, status);

  useEffect(() => {
    if (status === "loading") return; // 等待加载
    if (!session) {
      // 如果没有登录，重定向到登录页面
      router.push("/login");
    }
  }, [session, status, router]);

  return (
    <AuthContext.Provider value={{ session, status }}>
      {!session ?
      <div style={{position: "fixed", textAlign: 'center', left: 0, right: 0, top: '40%'}}>
        <Spinner label="" />
      </div> : children}
      {/* {false && children} */}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const { data: session, status } = useSession()
  return {session};
};