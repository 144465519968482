import {streamFetch} from '@/lib/request'

interface OptionsCallback {
  onbegin?: (response: Response) => void;
  onprogress?: (data: string, abortFetch: () => void) => void;
  onsuccess?: () => void;
  onerror?: (error: any) => void;
}

const baseUrl = process.env.NEXT_PUBLIC_AGENT_URL
export const chatMessageApi = (params:{
  message: string,
  conversation_id?: string,
  clientId: string,
  user?: string
}, optionsCallback: OptionsCallback) => {
  return streamFetch(
    baseUrl + '/v1/chat-messages',
    {
      "inputs": {
        "client_id": params.clientId
      },
      "query": params.message,
      "response_mode": "streaming",
      "conversation_id": params.conversation_id || "",
      "user": params.user || "wealth-manage-default",
    },
    optionsCallback,
    {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_AGENT_KEY}`
    }
  )
}

export const getconversationsApi = (params) => {
  return (new Promise((resolve, reject) => {
    try {
      const queryString = new URLSearchParams(params).toString();
      fetch(baseUrl + '/v1/conversations?' + queryString, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_AGENT_KEY}`,
        },
      }).then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      }).then(data => {
        resolve(data)
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      reject(error)
    }
  }))
}

export const deleteconversationApi = (conversationId: string, params: any) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(baseUrl + '/v1/conversations/' + conversationId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json', // 指定请求头为 JSON
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_AGENT_KEY}`,
        },
        body: JSON.stringify(params)
      }).then(response => {
        // 尝试解析响应体为 JSON
        return response.json().then(data => {
          return resolve({ status: response.status, data });
        });
      }).catch(error => {
        console.error('Error parsing response:', error);
        reject(error);
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      reject(error);
    }
  }) as Promise<{ status: number, data: any }>;
};

export const getMessagesApi = (params) => {
  return (new Promise((resolve, reject) => {
    try {
      const queryString = new URLSearchParams(params).toString();
      fetch(baseUrl + '/v1/messages?' + queryString, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_AGENT_KEY}`,
        },
      }).then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      }).then(data => {
        resolve(data)
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      reject(error)
    }
  }))
}
export const getMessagesSuggestedApi = (message_id:string, user: string) => {
  return (new Promise((resolve, reject) => {
    try {
      fetch(baseUrl + `/v1/messages/${message_id}/suggested?user=${user}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_AGENT_KEY}`,
        },
      }).then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      }).then(data => {
        resolve(data)
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      reject(error)
    }
  }))
}  
export const audioToTextApi = (formData: FormData) => {
  return (new Promise((resolve, reject) => {
    try {
      fetch(baseUrl + '/v1/audio-to-text', {
      // fetch('https://www.kofe.ai/api/audio-to-text', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_AGENT_KEY}`,
        },
        body: formData
      }).then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      }).then(data => {
        resolve(data)
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      reject(error)
    }
  })) as Promise<{ text: string }>
}

export const getAppSetInfoApi = () => {
  return (new Promise((resolve, reject) => {
    try {
      fetch(baseUrl + '/v1/parameters', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_AGENT_KEY}`,
        },
      }).then(response => {
        if (!response.ok) {
          // 如果响应状态码不在200-299之间， rejected
          return reject(response);
        }
        return response.json(); // 解析为json
      }).then(data => {
        resolve(data)
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      reject(error)
    }
  }))
}
