const translation = {
  分析: '分析',
  所属经理: '所屬經理',
  目标: '目标',
  状态: '狀態',
  建立日期: '建立日期',
  最近分析: '最近分析',
  风险概况: '風險概況',
  选择客户: '選擇客戶',
  刚刚: '剛剛',
  chat: {
    思考中: '思考中',
    思考秒: '思考 {{number}} 秒',
    推荐问: '\n推薦問:',
    消息异常: '消息異常',
  },
  menus: {
    首页: '首頁',
    文档: '文檔',
  },
  login: {
    登录描述: '欢迎使用我们的理财助手-与我们一起开启您的财富管理之旅，体验智能理财的魅力！',
    请输入用户名: '請輸入用戶名',
    请输入手机号: '請輸入手機號',
    输入邮箱: '輸入您的郵箱',
    输入密码: '輸入您的密碼',
    请输入密码: '請輸入密碼',
    注册账号: '註冊帳號',
    登录: '登錄',
    注册: '註冊',
  },
  userDetailTable: {
    投资类别: '投資類別',
    特定投资或证券的名称: '特定投資或證券的名稱',
    公开交易证券的股票代码或符号: '公開交易證券的股票代碼或符號',
    客户持有的股份或单位数量: '客戶持有的股份或單位數量',
    最初购买时的价格: '最初購買時的價格',
    当前市场价格: '當前市場價格',
    总价值: '總價值',
    原始成本: '原始成本',
    市场价值与成本基础之间的差额: '市場價值與成本基礎之間的差額',
    年收益率: '年收益率',
    投资收入: '投資收入',
    总资产分配给此资产的比例: '總資產分配給此資產的比例',
    风险水平: '風險水平',
    更新投资组合数据的日期: '更新投資組合數據的日期',
  },
  upload: {
    文件类型不支持: '文件類型不支持',
    文件太大: '文件太大了，不能超過 {{size}}MB',
    上传成功: '上傳成功',
    上传限制个数: '批量上傳限制{{number}}個',
    拖拽文件至此或者: '拖曳文件至此，或者',
    选择文件: '選擇文件',
    下载模板: '下载模板',
    支持文件: '已支持 {{exp}}，每個文件不超過 {{size}}MB'
  },
  api: {
    success: '成功',
    actionSuccess: '操作成功',
    saved: '已保存',
    create: '已創建',
    remove: '已移除',
  },
}

export default translation