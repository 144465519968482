import { createContext, useContext, useContextSelector } from 'use-context-selector';
import React from 'react';

const MenuContext = createContext<{ isCollapse: boolean; setIsCollapse: (isCollapse: boolean) => void }>({
  isCollapse: true,
  setIsCollapse: ()=>{}
});


interface MenuContextProviderProps {
  children: React.ReactNode;
}

export const MenuContextProvider: React.FC<MenuContextProviderProps> = ({ children }) => {
  const [isCollapse, setIsCollapse] = React.useState(true);

  return (
    <MenuContext.Provider value={{ isCollapse, setIsCollapse }}>
    { children }
    </MenuContext.Provider>
  )
};

export const useMenuContext = () => useContext(MenuContext)

export default MenuContext