const translation = {
  分析: 'Analyze',
  所属经理: 'Manager',
  目标: 'Goal',
  状态: 'Status',
  建立日期: 'Creation Date',
  最近分析: 'Recent Analyze',
  风险概况: 'Risk Profile',
  选择客户: 'Select Customer',
  刚刚: 'Just now',
  chat: {
    思考中: 'Thinking',
    思考秒: 'Thinking for {{number}} seconds',
    推荐问: '\nRecommended Questions:',
    消息异常: 'Message Exception',
  },
  index: {
    title: 'Client P&L Ratio',
    亏损客户数: 'Loss Accounts',
    盈利客户数: 'Profit Accounts',
    全部: 'All',
    亏损: 'Loss',
    盈利: 'Profit',
  },
  menus: {
    首页: 'Home',
    文档: 'Data',
    客户: 'Accounts',
    指导: 'User Guide',
  },
  login: {
    选择角色: 'Please select your role, are you a',
    登录描述: 'Welcome to our financial assistant - experience intelligent finance with us! Quack!',
    请输入用户名: 'Please enter username',
    请输入邮箱: 'Please enter email',
    获取验证码: 'Get code',
    请输入邮箱验证码: 'Enter email verification code',
    请确认密码: 'Please confirm the password',
    FirstName: 'First Name',
    LastName: 'Last Name',
    请输入手机号: 'Please enter phone number',
    输入邮箱: 'Enter your email',
    输入密码: 'Enter your password',
    请输入密码: 'Please enter password',
    注册账号: 'Register',
    登录: 'Login',
    注册: 'Register',
    不能为空: 'Required',
    密码规则: "Password must be at least 8 characters long and include numbers, letters, and special characters",
    两次输入的密码不一致: "The two passwords entered do not match",
    邮箱格式不正确: "Invalid email format",
    注册失败: "Registration failed",
    发送验证码失败: "An error occurred while sending the verification code, please try again later"
  },
  userDetailTable: {
    投资类别: 'Asset Class',
    特定投资或证券的名称: 'Security Name',
    公开交易证券的股票代码或符号: 'Ticker Symbol',
    客户持有的股份或单位数量: 'Quantity Held',
    最初购买时的价格: 'Purchase Price',
    当前市场价格: 'Current Price',
    总价值: 'Market Value',
    原始成本: 'Cost Basis',
    市场价值与成本基础之间的差额: 'Unrealized Gain/Loss',
    年收益率: 'Annual Yield ',
    投资收入: 'Income',
    总资产分配给此资产的比例: 'Allocation ',
    风险水平: 'Risk Level',
    更新投资组合数据的日期: 'Last Updated',
  },
  upload: {
    文件类型不支持: 'File type not supported',
    文件太大: 'File is too large, cannot exceed {{size}}MB',
    上传成功: 'Upload successful',
    上传限制个数: 'Batch upload limit {{number}} files',
    拖拽文件至此或者: 'Drag files here, or',
    下载模板: 'Download template',
    下载用户数据: 'Download user data',
    下载理财数据: 'Download investment data',
    上传用户或理财信息: 'Upload account or wealth data',
    上传理财信息: 'Upload financial information',
    选择文件: 'Select files',
    支持文件: 'Supported {{exp}}, each file cannot exceed {{size}}MB'
  },
  api: {
    success: 'Success',
    actionSuccess: 'Action Successful',
    saved: 'Saved',
    create: 'Created',
    remove: 'Removed',
  },
}

export default translation